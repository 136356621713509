<template>
<div class="app-main__inner">
      <div class="row">
          <div class="col-md-12">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="pe-7s-browser icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>{{ userName }} Mock Test
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3">
                <div class="card">
                  <div class="card-header text-white bg-danger specialColor">
                    Selected User Attempted Mock Tests
                  </div>
                  <div class="card-body mockBody">
                    <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
            <div class="col-12 collaps pl-0">
                <div v-for="(item, index) in mockTestList" :key="index">
                  <!-- <div > -->
                    <b-card :title="item.title" :sub-title="item.mock_status_type" >
                     
                      
                      <div class="col-12 text-right minWidth">
                        <b-button v-if="item.mock_status=='result'" @click="copyMockAnswers(item.user_mock_id,item.id)" class="mr-3" variant="outline-info">Copy Link</b-button>
                        <b-button v-if="item.mock_status=='result'" @click="getMockAnswers(item.user_mock_id,item.id)" class="mr-3" variant="outline-info">Generate Review</b-button>
                        <b-button v-if="item.mock_status=='result'" @click="getResult(item.user_mock_id)" class="mr-3" variant="outline-success">Show Result</b-button>                      
                        <b-button  v-if="item.user_id== user_id & item.user_mock_id!=null & item.mock_status=='Start'"  variant="outline-warning"><i class="fa fa-pause" aria-hidden="true"></i></b-button>
                        <b-button  v-else-if="user_type=='premium' | currentUser.is_admin=='T'" variant="outline-primary" ><i class="fa fa-play" aria-hidden="true"></i></b-button>

                      </div>
                    
                    </b-card>
                  <!-- </div> -->
                </div>
              </div>
              </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal ref="resultModal" id="resultModal" size="xl" scrollable hide-footer hide-header>
      <app-UserResultCard :userResultData='userResultData'/>
    </b-modal>
    <b-modal ref="answersModal" id="answersModal" size="xl" scrollable hide-footer title="Mock Answers Review">
      <app-Collaps :type="'mockAnswer'" :mockId="selectedMockId" :userMockId="selectedUserMockId"/>
    </b-modal>
    
</div>
</template>

<script>
import UserResultCard from '@/components/resultCard/userResultCard'
import Collaps from '@/components/reuseable/collaps' 
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
// import $ from 'jquery'
export default {
  name:"app-UserMockTests",
  components:{
    'app-UserResultCard':UserResultCard,
    'app-Collaps':Collaps
  },
  props:{
    onlyFree:{
      default:"All",
      type:String
    },
    selectedUserName:{
      default:'',
      type:String
    },
    selectedUserId:{
      default:0,
      type:Number
    },
    selectedUser:{
      type:Object
    }
  },
  data () {
    return {
      freeMenu:this.onlyFree,
        mockTestList:null,
        selectedMockId:0,
        selectedUserMockId:0,
        user_id:this.selectedUserId,
        userName:this.selectedUserName,
        user_type:'',
        currentUser:this.selectedUser,
        personalInfoSaved:false,
        personalInfo:null,
        userMockResults:null,
        extra_mock_allow:false,
        extra_mockTest:0,
        userResultData:{
          name:'',
          overAllScore: 0,
          scoreCardCode:'',
          testDate:'',
          validDate:'',
          reportIssueDate:'',
          personalImage:'',
          testTakerId: '',
          registrationId: '',
          communicativeSkille: {
            listening: 0,
            reading: 0,
            speaking: 0,
            writing: 0
          },
          enablingSkills:{
            grammar:0,
            oralFluency:0,
            pronunciation:0,
            spelling:0,
            vocabulary:0,
            writtenDiscourse:0
        },
          candidateInfo: {
            dob: '',
            countryResident: '',
            email: '',
            countryCitizen: '',
            gender: '',
            firstTimeTestTaker: ''
          }
        }
    }
  },
  methods: {
    ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData',
            UserPersonalInfofetch:'auth/fetchUserPersonalInfo',
        }),
        ...mapGetters({
            GetQuestionPractice:'auth/getPracticeNumber',
            getUserPersonalinfo: 'auth/getPersonalInfo',
        }),
        changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("ddd, MMMM Do YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("DD MMMM YYYY");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
       async checkUserPersonalInfo(){
          this.UserPersonalInfofetch('users/userid='+this.user_id).then(()=>{
            var response = this.getUserPersonalinfo();
            this.personalInfoSaved=(response.success==1)?true:false;
            this.personalInfo=response.data
            //console.log(this.personalInfo)
          }).finally(()=>{
            this.fetchMockTests()
            this.fetchAllResults()
          });
        },
        fetchMockTests(){
          this.freeMenu=this.onlyFree
          //console.log("Check User data"+this.currentUser.is_admin+", "+ this.extra_mock_allow)
            const url='overallPractice/getMockTestList/id='+this.user_id
            var vm=this;
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                console.log(response)
                if(response!=null)
                if(response.success==1){
                  //debugger
                    vm.mockTestList=response.data
                    //Sorting 
                    vm.mockTestList=vm.mockTestList.sort(function(a, b) {
                          if (a.id !== b.id) {
                              return a.id - b.id
                          }
                          if (a.name === b.name) {
                            return 0;
                          }
                          return a.name > b.name ? 1 : -1;
                      });
                      // filtering
                      vm.mockTestList=vm.mockTestList.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
                    //console.log(vm.freeMenu)
                    // if(vm.user_type=='Free' | vm.freeMenu=='Free')
                    // {
                    //   if(vm.extra_mock_allow){
                    //     let maxNumber=1 //vm.extra_mockTest+1//for free mock text
                    //     vm.mockTestList=vm.mockTestList.slice(0,maxNumber)
                    //   }
                    //   else{
                    //       vm.mockTestList=vm.mockTestList.filter((item)=>{
                    //       return item.mock_status_type =='Free'
                    //     })
                    //   }
                      
                    // }
                    // else if(vm.user_type == 'standard')
                    // {
                    //   let maxNumber= vm.extra_mockTest+11
                    //   vm.mockTestList=vm.mockTestList.slice(1,maxNumber)
                    // }
                    // else if(vm.user_type == 'premium')
                    // {
                    //   //debugger
                    //   let maxNumber= vm.extra_mockTest+21
                    //   vm.mockTestList=vm.mockTestList.slice(1,maxNumber)
                    // }
                    // As pr new instruction all users can see paid mocks as well.
                    let maxNumber= vm.extra_mockTest+21
                      vm.mockTestList=vm.mockTestList.sort(s=>s.id).slice(0,maxNumber)
                    
                }
            })
        },
        copyMockAnswers(user_mock_id,mockId){
          let copyText='https://result.pteclasses.com/mockreview/'+user_mock_id;
          navigator.clipboard.writeText(copyText);
        },
        getMockAnswers(user_mock_id,mockId){
          this.selectedMockId=mockId;
          console.log(this.selectedMockId);
          this.selectedUserMockId=user_mock_id
          console.log(this.selectedUserMockId);
          let url='https://result.pteclasses.com/mockreview/'+user_mock_id
          window.open(url,'_blank')

          //instead of popup a new window for review.
          //this.$refs['answersModal'].show()
        },
        getResult(user_mock_id){
          if(window.matchMedia("(max-width: 992px)").matches){
            return this.$alert("Mock-tests result is only available on System/Laptops.","Information","warning");
          }
          //console.log(user_mock_id)
          //console.log(this.userMockResults)
          const mockResult=this.userMockResults.filter((item)=>{
            return item.user_mock_id==user_mock_id
          })[0]
          //console.log(mockResult)
          //console.log(this.personalInfo)
          const mockResultJson=JSON.parse(mockResult.mock_result)
          this.userResultData.registrationId='REG0000'+this.user_id
          this.userResultData.testTakerId='PTE'+this.user_id+'0000'
          this.userResultData.scoreCardCode=user_mock_id.split('-')[0]
          this.userResultData.overAllScore=mockResultJson.overAll
          this.userResultData.testDate=this.changeDate(mockResult.saved_date,'shortDate')
          this.userResultData.communicativeSkille.listening=mockResultJson.listening
          this.userResultData.communicativeSkille.reading=mockResultJson.reading
          this.userResultData.communicativeSkille.writing=mockResultJson.writing
          this.userResultData.communicativeSkille.speaking=mockResultJson.speaking
          this.userResultData.enablingSkills.grammar=mockResultJson.grammar
          this.userResultData.enablingSkills.oralFluency=mockResultJson.oralFluency
          this.userResultData.enablingSkills.pronunciation=mockResultJson.pronunciation
          this.userResultData.enablingSkills.spelling=mockResultJson.spelling
          this.userResultData.enablingSkills.vocabulary=mockResultJson.vocabulary
          this.userResultData.enablingSkills.writtenDiscourse=mockResultJson.writtenDiscourse
          this.userResultData.name=this.currentUser.first_name+' '+this.currentUser.last_name
          this.userResultData.candidateInfo.email=this.currentUser.email_id
          this.userResultData.candidateInfo.dob=this.changeDate(this.personalInfo.dateofBirth,'shortDate')
          this.userResultData.candidateInfo.countryResident=this.personalInfo.residence_country
          this.userResultData.candidateInfo.countryCitizen=this.personalInfo.citizenship_country
          this.userResultData.candidateInfo.gender=this.personalInfo.gender
          this.userResultData.candidateInfo.firstTimeTestTaker=(this.userMockResults.length>1)?'No':'Yes'
          //console.log(this.personalInfo)
          this.userResultData.personalImage=this.personalInfo.image_path
          const currentdate=new Date()
          this.userResultData.reportIssueDate=this.changeDate(currentdate,'shortDate')
          var validDate=new Date(mockResult.saved_date)
          validDate=validDate.setMonth(validDate.getMonth()+24);// for two years
          this.userResultData.validDate=this.changeDate(validDate,'shortDate')
          //console.log(this.userResultData)
          this.$refs['resultModal'].show()
        },
        fetchAllResults(){
          const url='mocktestresult/mockResult/userId='+this.user_id
          this.fetchQuestionPractice(url).then(()=>{
                this.userMockResults=this.GetQuestionPractice().data
                //console.log(this.userMockResults)
            });
        },
        continuePratice(mockId,userMockId){
          if(this.user_type=='Free' | this.user_type=='standard'){
              this.$confirm(
                              "As per your current “Free” package, you would not be able to get your scores for this mock test.  To get your scores, please subscribe to the Premium Package. Click the below button to start the mock test.",
                              "Confirmation",
                              "question",
                                {
                                    confirmButtonText: "Buy Premium",
                                    cancelButtonText: "Continue"
                                }
                            )
                              .then((r) => {
                                if(r){
                                  this.$parent.callMenuClick('settings')
                                  return console.log("Purchase selected.")
                                }
                                else{
                                 // this.startMock(mockId);
                                 this.moveToMockTest(mockId,userMockId)
                                }
                              })
                              .catch(() => {
                                console.log("OK not selected.");
                               // this.startMock(mockId);
                               this.moveToMockTest(mockId,userMockId)
                              });
                  }
                  else{
                    this.moveToMockTest(mockId,userMockId)
                  }
          //this.$ls.set('user_mock_id',userMockId)
        },
        buyPremium(){
          this.$parent.callMenuClick('settings')
        },
        startMockTest(mockId){
          if(!this.personalInfoSaved)
          { 
            this.$parent.callMenuClick('settings')
            return this.$alert('You need to save personal info in settings to access mock tests','Failed','error')
          }
          if(this.user_type=='Free' | this.user_type=='standard'){
              this.$confirm(
                              "As per your current “Free” package, you would not be able to get your scores for this mock test.  To get your scores, please subscribe to the Premium Package. Click the below button to start the mock test.",
                              "Confirmation",
                              "question",
                                {
                                    confirmButtonText: "Buy Premium",
                                    cancelButtonText: "Continue"
                                }
                            )
                              .then((r) => {
                                if(r){
                                  this.$parent.callMenuClick('settings')
                                  return console.log("Purchase selected.")
                                }
                                else{
                                  this.startMock(mockId);
                                }
                              })
                              .catch(() => {
                                console.log("OK not selected.");
                                this.startMock(mockId); 
                              });
                  }
                  else{
                    this.startMock(mockId);
                  }
          
        },
        startMock(mockId){
                      var continueTest=this.mockTestList.filter((item)=>{
                      return item.id== mockId & item.mock_status=='Start'
                    })[0]
                    if(continueTest!=null)
                    {
                      this.$confirm(
                        "You already started this mock test, on new start your incomplete test data will be lost",
                        "Confirmation",
                        "question",
                        {
                            confirmButtonText: "Start New"
                        }
                        ).then(()=>{
                              this.moveToMockTest(mockId,null)
                        }).catch(() => {
                          // for default continue test I just need to uncomment this code.
                            //this.continuePratice(mockId,continueTest.user_mock_id)
                        });
                    }
                    else{
                      this.moveToMockTest(mockId,null)
                    }
        },
        moveToMockTest(mockId,user_mock_id){
          //console.log(mockId)
          this.$ls.set('mockTestId',mockId)
          this.$ls.set('user_mock_id',user_mock_id)
          this.$nextTick(function(){
            let routeData = this.$router.resolve({name: 'mockTest'});
            window.open(routeData.href, '_blank');
            })
        }
  },
 async beforeMount(){
   // this.user_id=this.$ls.get('user').user_id
   // this.currentUser=this.$ls.get('user')
    this.user_type=this.currentUser.user_type
    this.extra_mockTest=this.currentUser.extra_mockTest
    this.extra_mock_allow=this.currentUser.extra_mock_allow;
   // this.$ls.set('tabModule','') //Default all mock.
    //console.log(this.extra_mockTest)
   await this.checkUserPersonalInfo()
    //this.mockTestList=null;
    
  },
   watch: {
    $props: {
      handler() {
        this.fetchMockTests();
        this.$forceUpdate()
      },
      deep: true,
      immediate: true,
    },
   }
}
</script>
<style scoped>
.minWidth .btn
{
    min-width: 30px!important;
}
.mockBody .card-title{
  font-weight: bold!important;
    font-size: 1rem!important;
}
.mockBody .card-subtitle{
    font-size: .75rem;
    margin-top: 10px!important;
}
</style>