<template>
<div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="fa fa-users icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>Register Users</div>
                </div>
            </div>
        </div>
    </div>
  </div>
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3">
              <div class="card">
                <div class="card-header text-white bg-danger">
                  List of all registered users
                </div>
                <div class="card-body">
            <div class="overflow-auto">
      <b-row>

      <b-col lg="6" class="my-1 mb-2">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="outline-primary" :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="first_name">First Name</b-form-checkbox>
            <b-form-checkbox value="user_type">Type</b-form-checkbox>
            <b-form-checkbox value="email_id">Email</b-form-checkbox>
            <b-form-checkbox value="coaching">Coaching</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </b-row>
                <b-table striped hover :items="allUsers" :per-page="perPage"
                :current-page="currentPage" :fields="userFields"
                :filter="filter"
                :filter-included-fields="filterOn"
                >
                 <template #cell(user_id)="data">
                   <b-button :disabled="data.item.is_admin=='T'" variant="outline-info" @click="changeMembership(data.item.user_id)">
                     <i class="fa fa-edit" ></i>
                   </b-button>
                   <b-button style="margin-left: 10px;" variant="outline-info" @click="mockDetails(data.item.user_id)">
                     <!-- <i class="fa fa-edit" ></i> -->
                     <i class="fa fa-folder-open" aria-hidden="true"></i>
                   </b-button>
                  </template>
                  <template #cell(active_ind)="data">
                            <i :style="{color: data.item.active_ind ==='T'? 'green' : 'red'}" class="fas fa-dot-circle fa-xs" >
                             </i>
                          </template>
                 <template #cell(is_admin)="data">
                            <i v-if="data.item.is_admin==='T'">
                              ADMIN</i>
                            <i v-if="data.item.is_admin===null">
                              PTE CLASSES</i>
                          </template>
                          
                          <!-- <span v-if="!data.item.active">{{data.item.active}}<i class="fas fa-dot-circle"></i></span> -->
                <template #cell(pteExpiry)="data">
                <!-- <i v-if="data.item.pteExpiry==='No'">
                  </i>
                <i v-else-if="checkDate(data.item.coachingExpiry)">
                  {{data.item.coaching}}</i>
                  <i v-else>Expired</i> -->
                  <i v-if="data.item.pteExpiry===Null">
                  </i>
                <i v-else-if="checkDate(data.item.pteExpiry)">
                  {{changeDate(data.item.pteExpiry, "fullDate")}}</i>
                 
              </template>
                 <template #cell(first_name)="data">
                            <i>{{ data.item.first_name }}</i>
                          </template>
                          <template #cell(last_name)="data">
                            <i>{{ data.item.last_name }}</i>
                          </template>
                          <template #cell(email_id)="data">
                            <i>{{ data.item.email_id }}</i>
                          </template>
                          <template #cell(Title)="data">
                            <i style="text-transform:capitalize">{{ data.item.Title }}</i>
                          </template>
                          <template #cell(user_type)="data">
                            <i style="text-transform:capitalize">{{ data.item.user_type }}</i>
                          </template>
                          <template  #cell(creation_dte)="data">
                            <i>{{changeDate(data.item.creation_dte, "fullDate")}}</i>
                          </template>
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
            </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <b-modal
      id="modal-user-modification"
      ref="modalUserModification"
      :title="popupTitle"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      okTitle="Update"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="PTE Classes Plan"
          label-for="selectPlan"
        >
         <b-form-select id="selectPlan" v-model="user.ptePackage" :options="ptePackage"></b-form-select>
        </b-form-group>
        <b-form-group
          label="PTE Classes Expiry"
          label-for="expiryDate"
          class="label-datepicker"
        >
         <!-- <b-form-datepicker id="expiryDate" v-model="user.pteExpiry" class="mb-2"></b-form-datepicker> -->
          <date-picker v-model="user.pteExpiry" value-type="format" format="YYYY-MM-DD"></date-picker>
        </b-form-group>

        <b-form-group
          label="Coaching Plan"
          label-for="coachingPlan"
        >
         <b-form-select id="coachingPlan" v-model="user.coaching" :options="coaching"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Coaching Plan Expiry"
          label-for="coachingExpiry"
          class="label-datepicker"
        >
         <!-- <b-form-datepicker id="coachingExpiry" v-model="user.coachingExpiry" class="mb-2"></b-form-datepicker> -->
        <date-picker v-model="user.coachingExpiry" value-type="format" format="YYYY-MM-DD"></date-picker>
        </b-form-group>
        
        <b-form-group
          label="Extra Mock"
          label-for="extraMock"
        >
         <b-form-select id="extraMock" v-model="user.extra_mockTest" :options="extraMock"></b-form-select>
        </b-form-group>
        <b-form-group
          label="User Category"
          label-for="userCategory"
        >
         <b-form-select id="userCategory" v-model="user.category_id" :options="categoryPackages"></b-form-select>
        </b-form-group>
        <b-form-group
          label="User Status"
          label-for="userStatus"
        >
         <b-form-select id="userStatus" v-model="user.active" :options="statusList"></b-form-select>
        </b-form-group>
        <b-form-group
          label="Premium Video Allowed"
          label-for="premiumVideo"
        >
         <b-form-select id="premiumVideo" v-model="user.premium_video_allowed" :options="premiumAllowedList"></b-form-select>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal ref="userMocksModal" id="userMocksModal" size="xl" scrollable hide-footer >
      <app-UserMockTests :selectedUserName="selectedUserName" :selectedUserId="selectedUserId" :selectedUser="selectedUser"/>

    </b-modal>
</div>
</template>
<script>
import UserMockTests from '@/components/admin/UserMockTests' 
import moment from "moment";
import { mapActions,mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    props:['users'],
    components: { DatePicker,
      'app-UserMockTests':UserMockTests,
     },
    data(){
        return{
        perPage: 10,
        sortDirection: 'asc',
        currentPage: 1,
        popupTitle:'',
        minDate:'',
        maxDate:'',
        selectedUserName:'',
        selectedUserId:0,
        totalRows: 1,
        filter: null,
        filterOn: [],
        categoryPackages:[],
        statusList:[
          {value:true,text:'Active'},
          {value:false,text:'Inactive'}],
          premiumAllowedList:[
          {value:true,text:'Yes'},
          {value:false,text:'No'}],
        ptePackage:[
          { value: null, text: 'Please select an option' },
          { value: 'Free', text: 'Free (Minimum access)' },
          // { value: 'standard', text: 'Standard (Limited access)' },
          { value: 'premium', text: 'Premium (Unlimited access)' }
        ],
        extraMock:[
          { value: 0, text: 'Please select an option' },
          { value: 5, text: '5 Paid Mock'},
          { value: 10, text: '10 Paid Mock' },
          { value: 15, text: '15 Paid Mock' },
          { value: 20, text: '20 Paid Mock' },
          { value: 25, text: '25 Paid Mock' },
          { value: 30, text: '30 Paid Mock' },
          { value: 35, text: '35 Paid Mock' },
          { value: 40, text: '40 Paid Mock' },
          { value: 45, text: '45 Paid Mock' },
          { value: 50, text: '50 Paid Mock' },
          ],
        coaching:[
              { value: null, text: 'Please select an option' },
              { value:  '1-Month', text: 'Pro Master Practice Class' }
              // { value: '3-Month' , text: 'Three month Unlimited Coaching' },
              // { value: '6-Month' , text: 'Six month Unlimited Coaching' },
              // { value: '12-Month' , text: 'One year Unlimited Coaching' }
        ],
        user:{
          user_id:0,
          user_type:'',
          user_package:'',
          pteExpiry:null,
          coachingExpiry:null,
          ptePackage:null,
          coaching:null,
          url:'',
          pte_payment_type:'No',
          coaching_payment_type:'No',
          extra_mock_allow:false,
          extra_mockTest:0,
          category_id:0,
          Title:'',
          active:true,
          active_ind:'T',
          premium_video_allowed:1
        },
        userFields: [
          {
            key: 'user_id',
            label: '',
            sortable: false,
          }
          ,{
            key: 'active_ind',
            label: ''
          }
          ,{
            key: 'is_admin',
            label: 'PTE Classes',
            sortable: true,
            variant: 'success'
          }
          // ,{
          //   key: 'coaching',
          //   label: 'Coaching',
          //   sortable: true,
          //   variant: 'info'
          // }
          ,{
            key: 'pteExpiry',
            label: 'PTE Expiry',
            sortable: true,
            variant: 'info'
          }
          ,{
            key: 'first_name',
            label: 'First Name',
            sortable: true
          },
          {
            key: 'last_name',
            label: 'Last Name',
            sortable: true
          },
          {
            key: 'email_id',
            label: 'Email',
            sortable: true,
          },
          {
            key: 'Title',
            label: 'Category',
            sortable: true,
          },
          {
            key: 'user_type',
            label: 'Sub Type',
            sortable: true,
          },
          {
            key: 'creation_dte',
            label: 'Sign up Date',
            sortable: true,
          }
        ],
        selectedUser:null,
        allUsers:this.users
        }
    },
    computed: {
      rows() {
        return (this.allUsers==null)?0:this.allUsers.length
      }
    },
    methods:{
       ...mapActions({
            updateUser: 'auth/updateUserType',
            fetchUsersData:'auth/fetchGraphData',
        }),
        ...mapGetters({
            GetUsersData:'auth/getPracticeNumber',
        }),
      resetModal(){
        this.user={
          user_id:0,
          user_type:'',
          user_package:'',
          pteExpiry:null,
          coachingExpiry:null,
          ptePackage:null,
          coaching:null,
          pte_payment_type:'No',
          coaching_payment_type:'No',
          extra_mock_allow:false,
          extra_mockTest:0,
          category_id:0,
          Title:'',
          active:true,
          active_ind:'T'
        }
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      minMaxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      //minDate.setMonth(minDate.getMonth() - 2)
      minDate.setDate(minDate.getDate()+1)
      // 15th in two months
      const maxDate = new Date(today)
      maxDate.setMonth(maxDate.getMonth() + 12)
      //maxDate.setDate(15)
        this.minDate=minDate
        this.maxDate=maxDate
    },
     changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("YYYY-MM-DD");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
        checkDate(date){
          if(date==null | date=='') return false;
          const expiryDate = new Date(date);
          let currentDate=new Date();
         // const expiryDate=moment(date1).format("D/MM/yyyy");
         // currentDate=moment(currentDate).format("D/MM/yyyy");
          //console.log("Expiry Date: "+expiryDate+"    -  CurrentDate: "+currentDate);
          this.fetchCategories();
          return (expiryDate<currentDate)?false:true;
        },
        mockDetails(userId){
          this.selectedUser=this.allUsers.filter((user)=>{
            return user.user_id==userId
          })[0];
          this.selectedUserId=userId;
         this.selectedUserName=this.selectedUser.first_name+' '+this.selectedUser.last_name;
         this.$refs['userMocksModal'].show()
        },
        changeMembership(userId){
          this.selectedUser=this.allUsers.filter((user)=>{
            return user.user_id==userId
          })[0]
          console.log(this.selectedUser)
          if(this.selectedUser.is_admin=='T') return this.$alert("Sorry you cannot upgrade Admin users");
          this.popupTitle=(this.selectedUser!=null)?"Upgrade "+ this.selectedUser.first_name+' Plan':"No User Found";
          this.$refs['modalUserModification'].show()
          this.$nextTick(() => {
          this.user.pte_payment_type=this.selectedUser.pte_payment_type
          this.user.coaching_payment_type=this.selectedUser.coaching_payment_type
          this.user.ptePackage=(this.selectedUser.user_type!=null)? this.selectedUser.user_type:'Free';
          this.user.extra_mockTest=this.selectedUser.extra_mockTest;
          this.user.category_id=this.selectedUser.category_id;
          this.user.Title=this.selectedUser.Title;
          this.user.active=(this.selectedUser.active_ind=='T')?true:false;
          this.user.premium_video_allowed=(this.selectedUser.premium_video_allowed==1)?true:false;
          // var tempDate=new Date(this.selectedUser.pteExpiry).getDate()+1
          // //console.log(tempDate)
          this.user.coaching=(this.selectedUser.coaching!=null)? this.selectedUser.coaching:'No';
          this.user.pteExpiry=(this.selectedUser.pteExpiry!=null)? this.selectedUser.pteExpiry:null;
          this.user.coachingExpiry=(this.selectedUser.coachingExpiry!=null)? this.selectedUser.coachingExpiry:null;
          })
        },
        handleOk(){
          if(this.selectedUser.coaching!=this.user.coaching){
            this.user.coaching_payment_type='Cash'
          }
          //alert(this.user.ptePackage+"-"+this.selectedUser.user_type)
          if(this.selectedUser.user_type!=this.user.ptePackage){
            this.user.pte_payment_type='Cash'
          }
          if(this.user.extra_mockTest>0){
            this.user.extra_mock_allow=true;
          }
          if(this.selectedUser!=null) this.user.user_id=this.selectedUser.user_id;
          this.user.pteExpiry=this.changeDate(this.user.pteExpiry,'shortDate')
          //console.log(this.user.pteExpiry)
          this.user.coachingExpiry=this.changeDate(this.user.coachingExpiry,'shortDate')
          //alert(JSON.stringify(this.user))
          this.user.url = 'users/updateUserByAdmin'
              this.updateUser(this.user).then(()=>{
                this.$alert(
                            "User Information is updated successfully",
                            "Success",
                            "success",
                            {
                                confirmButtonText: "All Good!"
                            });
                this.$nextTick(()=>{
                  this.fetchUsers()
                  
                });
              });
        },
      fetchUsers(){
      var url='users/getall'
       this.fetchUsersData(url).then(()=>{
          const response=this.GetUsersData()
          this.allUsers=response.data
          
          //console.log(this.allUsers)
      });
      
      },
      fetchCategories(){
        var url='users/getCategoryList'
       this.fetchUsersData(url).then(()=>{
          const response=this.GetUsersData()
          //console.log(response);
          this.categoryPackages=response.data
          //console.log(this.categoryPackages)
      });
      }
    }
}
</script>
<style scoped>
.label-datepicker
{
    display: block!important;
}
.mx-datepicker
{
    width: 100%;
    border-color: #d0d6db;
}
</style>